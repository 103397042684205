<template>
    <div id="app">
        <!--        <keep-alive>-->
        <router-view/>
        <!--        </keep-alive>-->
    </div>

</template>
<script>
    export default {
        name: 'app',
        data() {
            return {
                
            }
        },
        metaInfo () {
            return {
                title: this.$store.state.Name
            }
        },
        created() {
            this.official();
            this.ifToken();
        },
        beforeunload(){
            localStorage.clear();
        },
        destroyed(){
            //清空缓存token,然后重新获取
            localStorage.clear();
        },
        methods: {
            official() {
                this.$axios.post('/api/h5/init')
                    .then(res => {
                        this.$store.commit('H5_name', res.data.data.name)
                    })
            },
            ifToken(){               
                let token = localStorage.getItem('token')
                if(token){
                    console.log('token已登录');
                }else{
                    this.getHuoqu();
                }              
            },
            getHuoqu(){
                let code = this.getQuery('code');
                let par = this.getEndQuery();
                if(code){
                    this.$axios.post('/api/wechat_official_account_login', { code: code }).then(res => {
                        localStorage.setItem("token", res.data.data.access_token)
                    }).catch(err => {
                        console.log('报错了',err.response.data.message);
                    })
                    console.log('code为true',code);
                    console.log(par);
                    
                }else{
                    let redirect_uri = encodeURIComponent(window.location.href);
                    window.location.href = `https://txweauth.gxhuatong.cn/api/wechat/accredit?appid=wxbb725fdfb6b0bd23&scope=snsapi_base&redirect_uri=${redirect_uri}`;
                }

                
            },
            getQuery (name) {
                // 未传参，返回空
                if (!name) return null;
                // 查询参数：先通过search取值，如果取不到就通过hash来取
                var after = window.location.search;
                after = after.substr(1) || window.location.hash.split('?')[1];
                // 地址栏URL没有查询参数，返回空
                if (!after) return null;
                // 如果查询参数中没有"name"，返回空
                if (after.indexOf(name) === -1) return null;

                var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
                // 当地址栏参数存在中文时，需要解码，不然会乱码
                var r = decodeURI(after).match(reg);
                // 如果url中"name"没有值，返回空
                if (!r) return null;

                return r[2];
            },
            getEndQuery (){
                    console.log(window.location.href);
                    console.log(window.location.hash);
                    let after = window.location.hash.split('?')[1];
                    if (!after) return null;
                    let obj = {};
                    after.split("&").forEach((item)=>{
                        obj[item.split("=")[0]] = item.split("=")[1];
                        
                    })
                    console.log(obj);
                    return obj;
            }
            
        },
    }
</script>
<style>

</style>
